<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
      />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-4"
          >
            {{ $t('RENEW_THE_PASSWORD') }}
          </b-card-title>
          <b-alert
            variant="danger"
            :show="errorShow"
          >
            <div class="alert-body font-small-2">
              <p class="mr-50">
                Les deux mots de passe ne sont pas identiques.
              </p>
            </div>
          </b-alert>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="checkLogin()"
            >
              <!-- email -->
              <b-form-group
                label="Email :"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-input
                    id="username"
                    v-model="email"
                    disabled
                    :state="errors.length > 0 ? false:null"
                    name="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">{{ $t('PASSWORD') }} :</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Mot de passe"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">{{ $t('CONFIRMATION') }} :</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Confirmation"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="passwordConfirmation"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="loading"
                variant="primary"
                type="button"
                block
                disabled
              >
                <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
              </b-button>
              <b-button
                v-else
                variant="primary"
                type="submit"
                block
              >
                {{ $t('TO_UPDATE') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BAlert, BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'

import fr from 'vee-validate/dist/locale/fr.json'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

localize('fr', fr)

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      passwordConfirmation: '',
      // validation rulesimport store from '@/store/index'
      required,
      email: '',
      loading: false,
      errorShow: false,
      token: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    localStorage.removeItem('internationalisation')
    // this.$store.dispatch('localeTraduction/fetchTraductions')
  },
  beforeMount() {
    this.token = this.$route.query.token
    this.$store.dispatch('auth/checkRenewPasswordToken', this.token)
      .then(
        reponse => {
          if (reponse.authentificated) {
            this.email = reponse.email
          }
        },
      )
      .catch(() => {
        this.$swal({
          width: 500,
          title: 'Changement de mot de passe impossible',
          text: 'Le lien a déja été utilisé ou a expiré',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.$router.push({ name: 'auth-login' })
      })
  },
  methods: {
    checkLogin() {
      const that = this
      this.loading = true
      this.errorShow = false

      this.$refs.loginValidation.validate().then(success => {
        if (success && this.password === this.passwordConfirmation) {
          this.$store.dispatch('auth/sendNewPassword', { email: this.email, password: this.password, token: this.token }).then(
            () => {
              that.loading = false
              that.$swal({
                width: 500,
                title: 'Changement confirmé',
                text: 'Votre mot de passe a bien été changé. \n\n Vous allez être redirigié vers la page de connexion',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$router.push({ name: 'auth-login' })
            },
          )
        } else {
          this.errorShow = true
          that.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
